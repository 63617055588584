import React from 'react'
import { Link } from 'react-router-dom'

import receipt from '../../assets/images/receipt-example.jpeg'
import payAddLogos from '../../assets/images/apple-samsung-google.png'
import payLogos from '../../assets/images/footer-payment.png'

const PayPolicy = () => {
  return (
    <div style={{ padding: 20 }}>
      <h3>Правила оплаты</h3>
      <p>Оплата банковскими картами осуществляется через ЗАО «Альфа-Банк».</p>
      <p>
        К оплате принимаются карты международных платежных систем VISA,
        MasterCard, платежной системы БЕЛКАРТ. Оплату также можно совершить,
        посредством сервиса Apple Pay.
      </p>
      <p>
        Безопасность совершения платежа обеспечивается современными методами
        проверки, шифрования и передачи данных по закрытым каналам связи. Ввод
        данных карточки осуществляется на защищенной авторизационной странице
        банка. Для оплаты необходимо ввести реквизиты карточки: номер, имя
        держателя, срок действия и трехзначный код безопасности. Трёхзначный код
        безопасности (CVV2 для VISA, CVC2 для MasterCard) — это три цифры,
        находящиеся на обратной стороне карточки. Если карточка поддерживает
        технологию 3DSecure или Интернет-пароль для держателей карточек БЕЛКАРТ,
        Вы будете перенаправлены на страницу банка, выпустившего карточку, для
        ввода кода безопасности. При оплате с помощью Apple Pay выберете карту
        из приложения Wallet, воспользуйтесь код-паролем или иным способом
        аутентификации, в зависимости от того, какой способ выбран в приложении.
      </p>
      <p>
        Предоставляемая Вами персональная информация (например: имя, адрес,
        телефон, e-mail, номер банковской карты и прочее) является
        конфиденциальной и не подлежит разглашению. Данные карточки передаются
        только в зашифрованном виде и не сохраняются на данном интернет-ресурсе.
      </p>
      <h6>Правила возврата</h6>
      <p>
        При оплате банковской платежной карточкой возврат наличными денежными
        средствами не допускается. Расчеты с потребителем при возврате
        уплаченной за товар денежной суммы, при расторжении договора о
        выполнении работы (оказании услуги) осуществляются в той же форме, в
        которой производилась оплата товара, работы (услуги), если иное не
        предусмотрено соглашением сторон.
      </p>
      <p>
        Порядок возврата регулируется правилами международных платежных систем.
      </p>
      <p>
        Процедура возврата товара регламентируется Законом Республики Беларусь
        от 9 января 2002 г. N 90-З «О защите прав потребителей».
      </p>
      <p>
        • Потребитель вправе отказаться от товара в течение 14(четырнадцати)
        дней с момента передачи ему товара;
      </p>
      <p>
        • Потребитель вправе в одностороннем порядке отказаться от исполнения
        договора о выполнении работы (оказании услуги) при условии оплаты
        исполнителю фактически понесенных им расходов, если иное не
        предусмотрено законодательством.
      </p>
      <p>
        • Требование потребителя об обмене либо возврате качественного товара
        подлежит удовлетворению, если товар не был в употреблении, сохранены его
        потребительские свойства и имеются доказательства приобретения его у
        данного продавца.
      </p>
      <p>
        • Перечень непродовольственных товаров надлежащего качества, не
        подлежащих обмену и возврату, утверждается Правительством Республики
        Беларусь.
      </p>
      <p>
        Для возврата денежных средств на банковскую платежную карточку
        необходимо заполнить «Заявление о возврате денежных средств» и отправить
        его по электронному адресу возврат@домен.by.
      </p>
      <p>
        {' '}
        Возврат денежных средств будет осуществлен на банковскую платежную
        карточку в течение 7 (семи) календарных дней со дня получения «Заявление
        о возврате денежных средств» Компанией. Сумма возврата будет равняться
        сумме покупки
      </p>
      <p>
        Для возврата денежных средств по операциям проведенными с ошибками
        необходимо обратиться с письменным заявлением и приложением
        чеков/квитанций, подтверждающих ошибочное списание. Данное заявление
        необходимо направить по электронному адресу 6502111@mail.ru.
      </p>
      <h6>Стоимость услуг</h6>
      <p>
        Стоимость оказанных услуг варьируется от 20 BYN до 300 BYN в зависимости
        от формата (индивидуальный или групповой).
      </p>
      <h6>Оплата банковскими картами через платежную систему BePaid</h6>
      <p>
        После нажатия кнопки «Оплатить» вы перейдете на специальную защищенную
        платежную страницу процессинговой системы{' '}
        <a href='https://bepaid.by' target='_blank' rel='noopener noreferrer'>
          bePaid
        </a>
      </p>
      <p>
        На платежной странице будет указан номер заказа и сумма платежа. Для
        оплаты вам необходимо ввести свои карточные данные и подтвердить платеж,
        нажав кнопку «Оплатить». Если ваша карта поддерживает технологию 3-D
        Secure, системой ваш будет предложено пройти стандартную одноминутную
        процедуру проверки владельца карты на странице вашего банка (банка,
        который выдал вашу карту). Обращаем ваше внимание, что после проведения
        платежа на указанный вами электронный адрес придет подтверждение оплаты.
      </p>
      <img style={{ maxWidth: 600 }} src={receipt} alt='Receipt' />
      <p>
        Просим вас сохранять данные оплат. Мы принимаем платежи по следующим
        банковским картам: Visa, Visa Electron, MasterCard, Maestro, Белкарт,
        Мир.
      </p>
      <img style={{ maxWidth: 300 }} src={payLogos} alt='pay' />
      <img style={{ maxWidth: 160 }} src={payAddLogos} alt='pay' />
      <p>
        Платежи по банковским картам осуществляются через систему электронных
        платежей{' '}
        <a href='https://bepaid.by' target='_blank' rel='noopener noreferrer'>
          bePaid
        </a>
        . Платежная страница системы bePaid отвечает всем требованиям
        безопасности передачи данных (PCI DSS Level 1). Все конфиденциальные
        данные хранятся в зашифрованном виде и максимально устойчивы к взлому.
        Доступ к авторизационным страницам осуществляется с использованием
        протокола, обеспечивающего безопасную передачу данных в Интернетe
        (SSL/TLS).
      </p>
      <p>
        Возврат денежных средств осуществляется на карту, с которой ранее была
        произведена оплата. Срок поступления денежных средств на карту от 1 до
        30 дней с момента осуществления возврата Продавцом. Условия возврата
        описаны в <Link to='/public'>Договоре оферты </Link> (п. 4.10)
      </p>
    </div>
  )
}

export default PayPolicy
