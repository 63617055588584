import React from 'react'

import useWindowDimensions from '../../utils'

import aboutBg from '../../assets/images/third-screen-bg.png'
import './about.css'

const About = () => {
  const { width } = useWindowDimensions()

  return (
    <div className='about__main'>
      {width > 720 && (
        <img src={aboutBg} className='about__main__bg_img' alt='about-bg' />
      )}
      <div className='about__main__text_block'>
        <div className='about__main__text_block__title'>
          <span className='about__main__text_block__title__add'>
            КВАЛИФИЦИРОВАННЫЙ
          </span>
          <br />
          ПСИХОЛОГ
        </div>
        {width <= 720 && (
          <img
            src={aboutBg}
            className='about__main__bg_img__mobile'
            alt='about-bg'
          />
        )}
        <div className='about__main__text_block__description'>
          Психолог, сертифицированный гештальт-терапевт, супервизор,
          ассоциированный тренер программы «Московский Гештальт Институт», член
          Общества Практикующих Психологов «Гештальт-подход», член РОО «Общество
          Психологов и Психотерапевтов «Гештальт-подход».
          <br /> <br /> Основные принципы моей работы - конфиденциальность,
          уважение к клиенту, ЛГБТ-френдли.
        </div>
        <div className='about__main__text_block__appointment'>
          <button
            className='about__main__text_block__appointment__button'
            onClick={() =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              })
            }
          >
            Запись на прием
          </button>
        </div>
      </div>
    </div>
  )
}

export default About
