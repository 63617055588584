import React from 'react'

import useWindowDimensions from '../../utils'

import mainBg from '../../assets/images/first-screen-bg.png'
import mainBgMobile from '../../assets/images/first-screen-bg-mobile.png'
import shape1 from '../../assets/images/first-screen-bg-shape-1.png'
import shape2 from '../../assets/images/first-screen-bg-shape-2.png'
import shape3 from '../../assets/images/first-screen-bg-shape-3.png'
import shape4 from '../../assets/images/first-screen-bg-shape-4.png'
import './main.css'

const Main = () => {
  const { width } = useWindowDimensions()

  return (
    <div className='main__main'>
      <div className='main__main__shadow' />
      {width > 720 && (
        <>
          <img src={mainBg} className='main__main__bg_img' alt='main-bg' />
          <img
            src={shape1}
            className='main__main__bg_img_shape1'
            alt='shape-1'
          />
          <img
            src={shape2}
            className='main__main__bg_img_shape2'
            alt='shape-2'
          />
          <img
            src={shape3}
            className='main__main__bg_img_shape3'
            alt='shape-3'
          />
          <img
            src={shape4}
            className='main__main__bg_img_shape4'
            alt='shape-4'
          />
        </>
      )}
      <div className='main__main__text_block'>
        <div className='main__main__text_block__title'>
          профессиональная
          <br />
          психологическая
          <br />
          помощь
        </div>
        {width <= 720 && (
          <img
            className='main__main__bg_img__mobile'
            src={mainBgMobile}
            alt='main-bg-mobile'
          />
        )}
        <div className='main__main__text_block__description'>
          Предлагаю вам поддержку и сопровождение в сложных жизненных ситуациях.
          Вместе мы сможем лучше понять, в чём суть ваших сложностей и{' '}
          <div className='main__main__text_block__description_add'>
            найти оптимальный выход
          </div>{' '}
          из сложившейся ситуации.
        </div>
        <div className='main__main__text_block__appointment'>
          <button
            className='main__main__text_block__appointment__button'
            onClick={() =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
              })
            }
          >
            Запись на прием
          </button>
        </div>
      </div>
    </div>
  )
}

export default Main
