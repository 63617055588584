import React, { useEffect, useState } from 'react'
import axios from 'axios'

import useWindowDimensions from '../../utils'

import recordBg from '../../assets/images/fourth-screen-bg.png'
import './record.css'

const botApiKey = '1250753081:AAHwv6SS8xlp_BmNSZz2PUVP299-TFpF1r0'

const Record = () => {
  const { width } = useWindowDimensions()

  const [values, setValues] = useState({
    username: '',
    phone: '',
    message: ''
  })
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        setIsModalVisible(false)
      }, 3000)
    }
  }, [isModalVisible])

  const handleRecordSubmit = () => {
    if (values.username || values.phone || values.message) {
      axios
        .get(
          `https://api.telegram.org/bot${botApiKey}/sendMessage?chat_id=@olgasobotkovskayaschedule&text=Имя - ${values.username}; Телефон - ${values.phone}; Сообщение - ${values.message}`
        )
        .then(() => {})
        .catch(() => {})
        .then(() => {
          setValues({
            username: '',
            phone: '',
            message: ''
          })
          setIsModalVisible(true)
        })
    }
  }
  return (
    <div className='record__main'>
      <div
        className='record__main__modal_container'
        style={{ display: isModalVisible ? 'flex' : 'none' }}
      >
        <div className='record__main__modal'>Ваш запрос отправлен !</div>
      </div>
      {width > 720 ? (
        <>
          <div className='record__main__shadow' />
          <img
            src={recordBg}
            className='record__main__bg_img'
            alt='record-bg'
          />
        </>
      ) : (
        <img
          src={recordBg}
          className='record__main__bg_img__mobile'
          alt='record-bg'
        />
      )}
      <div className='record__main__record_block'>
        <div className='record__main__record_block__title'>
          <span className='record__main__record_block__title__add'>
            ЗАПИШИТЕСЬ НА
          </span>
          <br />
          КОНСУЛЬТАЦИЮ
        </div>
        <div className='record__main__record_block__form'>
          <input
            value={values.username}
            onChange={(e) => setValues({ ...values, username: e.target.value })}
            type='text'
            placeholder='Как к Вам обращаться'
            className='record__main__record_block__form_item'
          />
          <input
            value={values.phone}
            onChange={(e) => setValues({ ...values, phone: e.target.value })}
            type='text'
            placeholder='Телефон'
            className='record__main__record_block__form_item'
          />
          <textarea
            value={values.message}
            onChange={(e) => setValues({ ...values, message: e.target.value })}
            placeholder='Сообщение'
            rows={4}
            className='record__main__record_block__form_item__textarea'
          />
        </div>
        <div className='record__main__record_block__appointment'>
          <button
            className='record__main__record_block__appointment__button'
            onClick={() => handleRecordSubmit()}
          >
            Запись на прием
          </button>
        </div>
      </div>
    </div>
  )
}

export default Record
