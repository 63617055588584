import React from 'react'

const Public = () => {
  return (
    <div style={{ padding: 20 }}>
      <h3>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ</h3>
      <p>
        на оказание информационно-консультационных услуг в сфере образования
        предлагается на условиях публичной оферты в соответствии со статьей 407
        частью 2 Гражданского кодекса Республики Беларусь.
      </p>
      <p>
        Настоящий Публичный договор на оказание консультационных услуг (далее -
        «Договор»), является Договором присоединения и определяет порядок
        оказания Индивидуальным предпринимателем Соботковской Ольгой
        Николаевной, действующий на основании свидетельства о государственной
        регистрации УНП No 291104622, выданного 17.05.2012 г., именуемый в
        дальнейшем «Исполнитель», и юридическое или физическое лицо, являющееся
        резидентом или гражданином Республики Беларусь, в дальнейшем именуемое
        «Заказчик», с другой стороны, именуемые в дальнейшем «Стороны»,
        заключили Настоящий Договор о нижеследующем:
      </p>
      <h6> 1. ПРЕДМЕТ ДОГОВОРА</h6>
      <p>
        1.1. Исполнитель принимает на себя обязательства по оказанию
        информационно- консультационных услуг в сфере образования в форме
        тематических семинаров, практикумов, лекций и консультаций по вопросам
        семьи и брака, в том числе дистанционных (вебинаров, онлайн-конференций
        и иных) (далее - Услуги) на возмездной основе, в соответствии с
        условиями настоящей публичной оферты.
      </p>
      <p>
        1.2. Заказчик, являющийся физическим лицом, принимает участие в
        мероприятиях в соответствии с условиями данного Договора.
      </p>
      <p>
        1.3. Заказчик, являющийся юридическим лицом, направляет для участия в
        мероприятиях своих сотрудников в соответствии с условиями данного
        Договора.
      </p>
      <p>
        1.4. Заказчик принимает услуги, оказанные Исполнителем, в соответствии с
        условиями данного Договора.
      </p>
      <p>
        1.5. Исполнитель оставляет за собой право вносить изменения в настоящий
        Договор публичной оферты и в иную информацию на сайте, в связи с чем
        заказчик обязуется перед получением Услуг ознакомиться с актуальной
        версией Договора и иной информации. Адрес действующей редакции Договора
        в сети интернет: https://olmind.by
      </p>
      <h6> 2. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h6>
      <p>
        2.1. Настоящий Договор является публичным договором (ст.396 Гражданского
        Кодекса Республики Беларусь), в соответствии с которым Исполнитель
        принимает на себя обязательство по оказанию Услуг в отношении
        неопределенного круга лиц, обратившихся за указанными Услугами.
      </p>
      <p>
        2.2. Публикация (размещение) текста настоящего Договора на официальном
        сайте Исполнителя по адресу: https://olmind.by является публичным
        предложением (офертой) Исполнителя, адресованным неопределенному кругу
        лиц заключить настоящий Договор (п.2.ст. 407 Гражданского Кодекса
        Республики Беларусь).
      </p>
      <p>
        2.3. Заключение настоящего Договора производится путем присоединения
        заказчика к настоящему Договору, т.е. посредством принятия (акцепта)
        Заказчиком условий настоящего Договора в целом, без каких-либо изъятий и
        оговорок (ст.398 Гражданского Кодекса Республики Беларусь).
      </p>
      <p>
        2.4. Фактом принятия (акцепта) Заказчиком условий настоящего Договора
        является оформление заявки на получение Услуги посредством нажатия
        кнопки «Регистрация», «Запись на приём» или «Купить» на странице
        мероприятия и внесением в форму заказа достоверных сведений о Заказчике
        (имени Заказчика, адреса электронной почты, номера мобильного телефона,
        а также иных сведений, необходимость внесения которых предусмотрена
        соответствующей формой заказа).
      </p>
      <p>
        2.5. Оплата осуществляется с помощью сервисов интернет-эквайринга,
        доступных на Сайте. При этом оплата считается осуществленной с момента
        зачисления соответствующей суммы денежных средств на расчетный счет
        Исполнителя.
      </p>
      <p>
        2.6. Договор действует с момента поступления оплаты на расчетный счет
        Исполнителя до момента завершения обязательств и взаиморасчетов между
        Сторонами.
      </p>
      <p>
        2.7. В подтверждение заключения Договора Заказчику на адрес электронной
        почты, указанный в форме заказа в соответствии с пунктом 2.4. Договора,
        в течение 1 (одного) рабочего дня с момента оплаты стоимости Услуги
        направляется Уведомление об успешном заказе, являющееся также
        уведомлением о заключении Договора.
      </p>
      <p>
        2.8. В соответствии с п. 3 ст. 404 Гражданского кодекса Республики
        Беларусь настоящий Договор считается заключенным в письменной форме, в
        г.Бресте, Республика Беларусь.
      </p>
      <h6> 3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h6>
      <p> 3.1. Исполнитель обязуется:</p>
      <p>
        3.1.1. Оказать Услуги Качественно и в полном объеме в соответствии с
        предусмотренным Договором.
      </p>
      <p>
        3.1.2. Организовать материально-техническое обеспечение образовательного
        процесса в соответствии с установленными санитарными нормами, правилами
        и гигиеническими нормативами.
      </p>
      <p>
        3.1.3. Своевременно информировать об изменениях, возникающих в ходе
        оказания Услуги: сроках и месте проведения путем публикации информации в
        сети Интернет на сайтах Исполнителя по адресу https://olmind.by
      </p>
      <p>
        3.1.4. Сохранять коммерческую тайну Заказчика по любым вопросам, которые
        стали известны Исполнителю в связи с оказываемыми Услугами, и обеспечить
        конфиденциальность личной информации участников семинаров, лекций,
        консультаций.
      </p>
      <p> 3.2. Исполнитель имеет право:</p>
      <p>
        3.2.1. Определять самостоятельно формы, методы и способы осуществления
        образовательного процесса в соответствии с требованиями законодательства
        Республики Беларусь.
      </p>
      <p>
        3.2.2. На использование объектов интеллектуальной собственности
        Заказчика, созданных в период действия Договора, а также материалов
        объектов в иллюстративных, демонстрационных и рекламных целях, включая,
        но не ограничиваясь, публикацией в Интернет и использованием в печатных
        материалах, с указанием авторства работы во всех случаях, когда это
        будет сочтено Исполнителем возможным.
      </p>
      <p> 3.3. Заказчик обязуется:</p>
      <p>
        3.3.1. Оплатить Услуги в соответствии с условиями Раздела 4 Договора.
      </p>
      <p>
        3.3.2. Добросовестно относиться к освоению содержания образовательных
        программ.
      </p>
      <p>
        3.3.3. Сохранять конфиденциальность личной информации других участников
        образовательного процесса по любым вопросам, которые стали ему известны
        в ходе обучения. 3.3.4. Выполнять требования правил Исполнителя для
        обучающихся, иных локальных нормативных актов Исполнителя.
      </p>
      <p> 3.3.5. Бережно относится к имуществу Исполнителя.</p>
      <p>
        3.3.6. Самостоятельно следить за расписанием проводимых семинаров,
        публикуемым на официальном сайте исполнителя, и своевременно проверять
        корреспонденцию, поступающую на адрес электронной почты, который
        заказчик указал в регистрационной форме при подаче заявки.
      </p>
      <p>
        3.4. Заказчик имеет право на получение платной услуги в сфере
        образования в соответствии с пунктом 1 настоящего договора.
      </p>
      <p>
        3.5. Предоставление Услуг в рамках Договора для Заказчиков, являющихся
        юридическими лицами, подтверждается Актом об оказании услуг (далее -
        «Акт»), который составляется и подписывается Исполнителем единолично на
        основании Закона Республики Беларусь «О бухгалтерском учете и отчетности
        (п6. Ст. 10) и Постановления Министерства финансов Республики Беларусь
        «О некоторых вопросах составления первичных учетных документов» No58 от
        21 декабря 2015 года при отсутствии мотивированных претензий от
        Заказчика в течение 5 (пяти) календарных дней с момента оказания Услуг;
      </p>
      <p>
        3.6. Предоставление Услуг в рамках Договора для Заказчиков, являющихся
        физическими лицами, подтверждается фактом потребления Заказчиком
        оказанных ему Услуг и отсутствием предъявленных Заказчиком письменных
        претензий по оказанным Исполнителем Услугам в течение 5 (пяти)
        календарных дней с момента их оказания.
      </p>
      <h6> 4. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h6>
      <p>
        4.1. Исполнитель, используя имеющиеся в его распоряжении сетевые
        ресурсы, предоставляет подробную информацию об Услугах, а также правилах
        и условиях проведения мероприятий в виде текстовой информации, аудио или
        видео презентаций.
      </p>
      <p>
        4.2. Стоимость Услуг, оказываемых в рамках настоящего Договора,
        определяется исходя из объема, характера и продолжительности Услуг и
        отражается в публикуемых на сайте информационных материалах или в счете
        (счете-фактуре), предоставленных Заказчику для оплаты.
      </p>
      <p>
        4.3. Исполнитель предоставляет Услуги на условиях 100% предоплаты суммы,
        указанной в счете (сете-фактуре) предоставленной Заказчику.
      </p>
      <p>
        4.4. Заказчики, являющиеся юридическими лицами, осуществляют оплату
        Услуг посредством безналичного перевода денежных средств на расчетный
        счет исполнителя согласно данным и реквизитам, указанным в
        предоставленном счете-фактуре.
      </p>
      <p>
        4.5. Заказчики, являющиеся физическими лицами, осуществляют оплату
        заказанных Услуг посредством безналичного банковского или почтового
        перевода денежных средств на расчетный счет Исполнителя, согласно данным
        и реквизитам, указанным в предоставленном счете, а также посредством
        банковских карт с помощью сервисов интернет-эквайринга, доступных на
        сайте.
      </p>
      <p>
        4.6. Все издержки, связанные с перечислением платежей по настоящему
        договору на счет Исполнителя, несет Заказчик.
      </p>
      <p>
        4.7. В случае изменения налогового законодательства, конъюнктуры рынка,
        а также введения дополнительных налогов и обязательных платежей,
        Исполнитель оставляет за собой право в одностороннем порядке изменять
        стоимость Услуг, о чем обязан проинформировать Заказчика не позднее, чем
        за 10 (десять) календарных дней до изменения стоимости. Изменение
        стоимости Услуг не распространяется на уже оплаченные Услуги.
      </p>
      <p>
        4.8. В случае финансовой задолженности Заказчика (нарушение сроков и
        порядка оплаты) Исполнитель оставляет за собой право приостановить
        оказание Услуг по данному Договору, в том числе путем не допуска
        Заказчика до предоставления Услуг.
      </p>
      <p>
        4.9. В случае отсутствия на мероприятии или консультации без
        уважительной причины стоимость пропущенной услуги не возвращается. При
        пропуске по уважительной причине и при предъявлении документа, выданного
        компетентным органом (в случае болезни и др.), Заказчик может перенести
        дату консультации или посетить подобное мероприятие в другое время,
        предварительно согласовав с Исполнителем время и место.
      </p>
      <p>
        4.10. Оплаченная стоимость Услуг может быть возвращена Заказчику при
        невозможности оказать Услугу по вине Исполнителя, о чем последний
        немедленно уведомляет Заказчика. Возврат денежных средств будет
        осуществлен на банковскую платежную карточку, с которой осуществлялся
        платеж, в течение 7 (семи) календарных дней со дня уведомления. Сумма
        возврата будет равняться сумме оплаты. Срок поступления денежных средств
        на карту от 1 до 30 дней с момента осуществления возврата Продавцом.
      </p>
      <h6> 5. ОТВЕТСТВЕННОСТЬ СТОРОН И ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h6>
      <p>
        5.1. За невыполнение или ненадлежащее выполнение условий Договора
        Стороны несут ответственность, предусмотренную действующим
        законодательством Республики Беларусь и Договором.
      </p>
      <p>
        5.2. Нарушением условий Договора считается его невыполнение или
        ненадлежащее выполнение, т.е. выполнение с нарушением условий,
        определенных содержанием Договора.
      </p>
      <p>
        5.3. В случае расторжения договора по инициативе Заказчика, денежные
        средства, плаченные Заказчиком за предоставленные услуги, возврату не
        подлежат.
      </p>
      <p>
        5.4. Сторона освобождается от определенной настоящим Договором и
        действующим законодательством Республики Беларусь ответственности за
        полное или частичное нарушение Договора, если она докажет, что такое
        нарушение случилось в результате действия форс- мажорных обстоятельств,
        при условии, что их наступление подтверждается документом, выданным
        компетентным государственным органом.
      </p>
      <p>
        5.5. Все споры, которые возникают по поводу выполнения настоящего
        Договора или связанные с ним, решаются путем проведения переговоров
        между Сторонами.
      </p>
      <p>
        5.6. Если соответствующий спор невозможно решить путем проведения
        переговоров, он решается в судебном порядке в соответствии с
        установленной подведомственностью и подсудностью такого спора, согласно
        действующему законодательству Республики Беларусь.
      </p>
      <h6> 6. СРОК ДЕЙСТВИЯ ДОГОВОРА</h6>
      <p>
        6.1. Настоящий Договор считается заключенным с момента зачисления на
        расчетный счет Исполнителя денежных средств, уплаченных Заказчиком в
        счет оплаты заказанных им Услуг.
      </p>
      <p>
        6.2. Договор может быть расторгнут досрочно по соглашению Сторон или в
        одностороннем порядке в случаях, предусмотренных Договором. В случае
        расторжения Договора взаиморасчеты между Сторонами проводятся в
        соответствии с условиями Договора.
      </p>
      <h6> 7. ПОРЯДОК РЕШЕНИЯ СПОРОВ И РАЗНОГЛАСИЙ</h6>
      <p>
        7.1. Все споры и разногласия, которые могут возникнуть из настоящего
        Договора или в связи с ним, Стороны решают путем соглашения.
      </p>
      <p>
        7.2. Все вопросы, возникающие из настоящего Договора или относящиеся к
        нему, которые Стороны не могут урегулировать мирным путем, передаются на
        окончательное разрешение компетентного суда Республики Беларусь.
      </p>
      <p>
        7.3. Во всем неурегулированном настоящим Договором, стороны
        руководствуются законодательством Республики Беларусь.
      </p>
      <h6>8. АДРЕСА, РЕКЗИЗИТЫ СТОРОН</h6>
      <p>
        8.1. Стороны безоговорочно соглашаются под реквизитами Заказчика считать
        информацию, казанную им при оформлении заказа на предоставление Услуг.
      </p>
      <p>8.2. Реквизиты Исполнителя:</p>
      <p>Индивидуальный предприниматель Соботковская Ольга Николаевна</p>
      <p>УНП 291104622 </p>
      <p>Адрес: г. Брест, ул. Васнецова, д. 36</p>
      <p>
        Расчетный счет IBAN: BY04 ALFA 3013 2684 3700 1027 0000 (в BYN), в ЗАО
        «АЛЬФА-БАНК».
      </p>
      <p>Код банка ALFABY2X, УНП банка 101541947 </p>
      <p>Контактные данные: тел. +375296502111; e-mail: 6502111@mail.ru</p>
    </div>
  )
}

export default Public
