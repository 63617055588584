import React from 'react'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { BrowserRouter as Router } from 'react-router-dom'

import 'pure-react-carousel/dist/react-carousel.es.css'
import 'rsuite/dist/styles/rsuite-default.css'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
