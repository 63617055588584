import React from 'react'

import useWindowDimensions from '../../utils'

import logo from '../../assets/icons/logo.svg'
import facebookIcon from '../../assets/icons/facebook.svg'
import instagramIcon from '../../assets/icons/instagram.svg'
import phoneIcon from '../../assets/icons/phone.svg'
import './header.css'

const Header = () => {
  const { width } = useWindowDimensions()

  return (
    <header className='header__main'>
      <div className='header__main__item_block'>
        <img src={logo} alt='logo' className='header__main__item_block__bg' />
      </div>
      <div className='header__main__item_block'>
        <div className='header__main__item_block__flex header__main__item_block__bg'>
          <a href='https://instagram.com/olgasobotkovskaya'>
            <img
              className='header__main__item_block__img'
              src={instagramIcon}
              alt='inst'
            />
          </a>
          <a href='https://www.facebook.com/olga.sobotkovskaya'>
            <img
              className='header__main__item_block__img'
              src={facebookIcon}
              alt='fb'
            />
          </a>
          {(width >= 1024 || width <= 720) && (
            <div className='header__main__item_block__text'>
              Это мои личные аккаунты
              <br />в социальных сетях
            </div>
          )}
        </div>
      </div>
      <div className='header__main__item_block header__main__item_block_contact__add'>
        <div className='header__main__item_block__flex header__main__item_block_contact header__main__item_block__bg'>
          <div className='header__main__item_block__phone_block'>
            <img
              className='header__main__item_block__phone_block__img'
              src={phoneIcon}
              alt=''
            />
            <a href='tel:+375296502111'>+375 (29) 650-21-11</a>
          </div>
          <div className='header__main__item_block__contact_block'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth'
                })
              }}
            >
              ВАМ ПЕРЕЗВОНИТЬ?
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
