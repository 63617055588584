import React from 'react'
import TrackVisibility from 'react-on-screen'
import { Switch, Route } from 'react-router-dom'
import Layout from './components/layout'

import Main from './pages/main'
import Terms from './pages/terms'
import About from './pages/about'
import Record from './pages/record'
import History from './pages/history'
import Work from './pages/work'
import Footer from './pages/footer'
import Policy from './pages/policy'
import Public from './pages/public'
import PayPolicy from './pages/pay-policy'

const App = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Layout>
          <Main />
          <TrackVisibility offset={400} partialVisibility>
            {({ isVisible }) => <Terms isVisible={isVisible} />}
          </TrackVisibility>
          <Work />
          <About />
          <History />
          <Record />
          <Footer />
        </Layout>
      </Route>
      <Route exact path='/policy'>
        <Policy />
      </Route>
      <Route exact path='/public'>
        <Public />
      </Route>
      <Route exact path='/pay-policy'>
        <PayPolicy />
      </Route>
    </Switch>
  )
}

export default App
