import React, { useState } from "react";
import { Link } from "react-router-dom";
import payAddLogos from "../../assets/images/apple-samsung-google.png";
import payLogos from "../../assets/images/footer-payment.png";
import "./footer.css";

const Footer = () => {
  const [amount, setAmount] = useState(0);
  // const [currency, setCurrency] = useState("USD");
  const handlePay = () => {
    const params = {
      checkout_url: "https://checkout.bepaid.by",
      checkout: {
        iframe: true,
        transaction_type: "payment",
        public_key:
          "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxCfjW14QznL5E9uYkoZdJIapkzFkCl6LTQiNDtus82ZfpgVyGmltUts+IHzxUQ0CwnvbUUfVqeCVJp0CoINeAeJqzKBcYOaf3YuQcKBTUKSu7d1KzXmYIQy8Ie7KSRKF5mm/kqkiSAM/Da/OPWA4IJMUov+ZLmE6328MqewiDK5T+k5v2ex7xCqmGPfucYQEX7HOGVZv6MuQkIk/5x8jJrEWA/pcwooKcGM729eDOmjyVKjNo9gha7DbRsU79HF6pfxrMgpJjUR4XtZMZMDqFM69CLVsv63EYupl2+8NuHhjE2qHfGEtYtOqgqiS+o6D2WrW/EOVKsv9Doi5hdePVQIDAQAB",
        order: {
          amount: amount * 100,
          currency: "BYN",
          description: "Оплата консультации"
        }
      },
      closeWidget: function() {
        console.debug("Close widget callback");
      }
    };

    // @ts-ignore
    new window.BeGateway(params).createWidget();
  };
  return (
    <div className="footer__main">
      <div className="footer__main__text_block">
        <div className="footer__main__text_block__logos">
          <img
            src={payLogos}
            alt="PAY"
            className="footer__main__text_block__logos__img"
          />
          <img
            src={payAddLogos}
            alt="PAY"
            className="footer__main__text_block__logos__img_add"
          />
        </div>
        <div className="footer__main__text_block__info">
          <div>Название компании: ИП "Соботковская О.Н."</div>
          <div>УНП 291104622</div>
          <div>
            Адрес: 224023, Республика Беларусь, Брест, ул. Васнецова, д.36
          </div>
          <div>
            Свидетельство о государственной регистрации № 291104622 от
            17.05.2012 выдано Администрацией Московского района г. Бреста
          </div>
          <div>Режим работы 10:00 – 20:00</div>
          <div>
            Телефон: <a href="tel:+375296502111">+375 (29) 650-21-11</a>
          </div>
          <div>
            E-mail: <a href="mailto:6502111@mail.ru">6502111@mail.ru</a>
          </div>
        </div>
      </div>
      <div className="footer__main__action_block">
        <Link to="/policy">Политика обработки персональных данных</Link>
        <Link to="/public">Договор публичной оферты</Link>
        <Link to="/pay-policy">Правила оплаты</Link>
        <div className="footer__main__text_block__pay">
          <button
            className="footer__main__text_block__pay__button"
            onClick={() =>
              (window.location.href =
                "https://ecom.alfabank.by/shortlink/raOxyLvI")
            }
          >
            Оплатить - А
          </button>
        </div>
        <div className="divider__footer"/>
        <input
          value={amount}
          onChange={(e) => setAmount(+e.target.value)}
          type="number"
          min={1}
          placeholder="Сумма"
          className="footer__main__text_block__pay_input"
        />
        {/*<select*/}
        {/*  className="footer__main__text_block__pay_input"*/}
        {/*  value={currency}*/}
        {/*  onChange={(e) => setCurrency(e.target.value)}*/}
        {/*>*/}
        {/*  <option value="USD">USD</option>*/}
        {/*  <option value="RUB">RUB</option>*/}
        {/*  <option value="EUR">EUR</option>*/}
        {/*  <option value="BYN">BYN</option>*/}
        {/*</select>*/}
        <div className="footer__main__text_block__pay">
          <button
            disabled={!amount}
            className="footer__main__text_block__pay__button"
            onClick={handlePay}
          >
            Оплатить - Б
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
