import React, { useEffect, useState } from 'react'

import card1 from '../../assets/images/card-1.png'
import card2 from '../../assets/images/card-2.png'
import card3 from '../../assets/images/card-3.png'
import './terms.css'

type Props = {
  isVisible: boolean
}

const Terms = ({ isVisible }: Props) => {
  const [isStartAnimation, setIsStartAnimation] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setIsStartAnimation(true)
    }
  }, [isVisible])

  return (
    <div className='terms__main'>
      <div className='terms__main__title_block'>
        Работаю со{' '}
        <span className='terms__main__title_block__add'>взрослыми</span> очно
        <br />и онлайн
      </div>
      <div
        className='terms__main__cards_block'
        style={{
          opacity: isStartAnimation ? 1 : 0,
          transform: isStartAnimation ? 'translateY(0px)' : 'translateY(200px)',
          transition: 'all 0.3s ease-out',
          transitionDelay: '0.2s'
        }}
      >
        <div className='terms__main__cards_block__card_item'>
          <img src={card1} alt='card-1' />
          <div className='terms__main__cards_block__card_item__description'>
            Индивидуальные
            <br />
            консультации
          </div>
        </div>
        <div className='terms__main__cards_block__card_item'>
          <img src={card2} alt='card-2' />
          <div className='terms__main__cards_block__card_item__description'>
            Консультация
            <br />
            супружеских пар
          </div>
        </div>
        <div className='terms__main__cards_block__card_item'>
          <img src={card3} alt='card-3' />
          <div className='terms__main__cards_block__card_item__description'>
            Групповая терапия
            <br />
            Индивидуальная и групповая супервизия
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms
