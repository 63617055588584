import React from 'react'
import { Icon, Steps } from 'rsuite'

import './work.css'

const styles = {
  width: '100%',
  display: 'inline-table',
  verticalAlign: 'top',
  fontSize: 18,
  letterSpacing: 'calc(-3px / 100)',
  color: '#1b6b8f',
  fontWeight: 300
}

const Work = () => {
  return (
    <div className='work__main'>
      <div className='work__main__text_block'>
        <div className='work__main__text_block__title'>
          <span className='work__main__text_block__title__add'>
            НАПРАВЛЕНИЯ
          </span>
          <br />
          РАБОТЫ
        </div>
        <div className='work__main__text_block__description'>
          <Steps vertical style={styles} small>
            <Steps.Item
              status='finish'
              title={
                <span className='work__main__text_block__description__text work__main__text_block__description__text_title'>
                  Краткосрочное индивидуальное консультирование и долгосрочная
                  терапия:
                </span>
              }
              description={
                <span className='work__main__text_block__description__text'>
                  <ul>
                    <li>
                      сложные эмоциональные состояния (тревога, внутриличностные
                      конфликты, горе, утрата, травма, проблемы выбора);
                    </li>
                    <li>
                      переживание злости, обиды, страха, стыда, вины и других
                      чувств;
                    </li>
                    <li>
                      вопросы с самооценкой, неудовлетворенность собой,
                      трудности в построении личных границ, одиночество,
                      отсутствие смыслов в жизни;
                    </li>
                    <li>
                      самопознание, поиск собственного потенциала и новых
                      ресурсов;
                    </li>
                    <li>
                      трудности принятия собственной мужественности,
                      женственности, сексуальные отношения.
                    </li>
                  </ul>
                </span>
              }
              icon={<Icon icon='search-peoples' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='work__main__text_block__description__text work__main__text_block__description__text_title'>
                  Семейное консультирование:
                </span>
              }
              description={
                <span className='work__main__text_block__description__text'>
                  <ul>
                    <li>частые конфликты, агрессия в семье;</li>
                    <li>
                      супружеские измены, ревность, любовный треугольник,
                      развод;
                    </li>
                    <li>
                      созависимость или нездоровая эмоциональная зависимость
                      людей друг от друга;
                    </li>
                    <li>отношения взрослых детей с родителями и др.</li>
                  </ul>
                </span>
              }
              icon={<Icon icon='search-peoples' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='work__main__text_block__description__text work__main__text_block__description__text_title'>
                  Работа с группами:
                </span>
              }
              description={
                <span className='work__main__text_block__description__text'>
                  <ul>
                    <li>тематические психологические тренинги;</li>
                    <li>длительные терапевтические группы;</li>
                    <li>
                      обучающие программы 1 и 2 ступени по гештальт-терапии
                      (Московский Гештальт Институт);
                    </li>
                    <li>лекции.</li>
                  </ul>
                </span>
              }
              icon={<Icon icon='search-peoples' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='work__main__text_block__description__text work__main__text_block__description__text_title'>
                  Супервизия психологов:
                </span>
              }
              description={
                <span className='work__main__text_block__description__text'>
                  <ul>
                    <li>
                      поддержка в развитии практики психотерапевтов и
                      консультантов;
                    </li>
                    <li>тематическая супервизия;</li>
                    <li>супервизия учебных троек в рамках гештальт-подхода;</li>
                    <li>супервизорские группы.</li>
                  </ul>
                </span>
              }
              icon={<Icon icon='search-peoples' size='lg' />}
            />
          </Steps>
        </div>
      </div>
    </div>
  )
}

export default Work
