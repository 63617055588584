import React from 'react'
import { Icon, Steps } from 'rsuite'

import './history.css'

const styles = {
  width: '100%',
  display: 'inline-table',
  verticalAlign: 'top',
  fontSize: 18,
  letterSpacing: 'calc(-3px / 100)',
  color: '#1b6b8f',
  fontWeight: 300
}

const History = () => {
  return (
    <div className='history__main'>
      <div className='history__main__text_block'>
        <div className='history__main__text_block__title'>
          <span className='history__main__text_block__title__add'>
            ПРОФЕССИОНАЛЬНЫЙ ОПЫТ
          </span>
          <br />И ОБРАЗОВАНИЕ
        </div>
        <div className='history__main__text_block__description'>
          <Steps vertical style={styles} small>
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  1987 - 1992 гг.
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  БГУ, физический факультет, специальность «Физик. Преподаватель
                  физики».
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  1992 - 1993 гг.
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  БГПУ им. М.Танка, факультет переподготовки кадров образования,
                  специальность «Практический психолог».
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  1994 - 1997 гг.
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  Очная аспирантура при Национальном Институте Образования РБ,
                  специальность «социальный психолог».
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  1997 - 2013 гг.
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  БрГТУ, старший преподаватель кафедры социально-политических и
                  исторических наук, лекционные и практические занятия по курсам
                  «Основы психологии и педагогики», «Психология межличностных
                  отношений», «Конфликтология», «Психология делового общения»,
                  «Этнопсихология». Автор ряда научных статей и
                  учебно-методических пособий в области психологии.
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  2009 - 2017 гг.
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  Программа «Московский Гештальт Институт» (1-3 ступень),
                  сертифицированный гештальт-терапевт и супервизор.
                  Специализации «Гештальт-терапия с семьей», «Сексология в
                  гештальт-подходе», «Психическая травма. Современные взгляды,
                  структура и методы психотерапии», «Основы семейного
                  консультирования».
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
            <Steps.Item
              status='finish'
              title={
                <span className='history__main__text_block__description__text history__main__text_block__description__text_title'>
                  2017 - н/д
                </span>
              }
              description={
                <span className='history__main__text_block__description__text'>
                  На сегодняшний день ассоциированный тренер программы
                  «Московский Гештальт Институт», член Общества Практикующих
                  Психологов «Гештальт-подход», член РОО «Общество Психологов и
                  Психотерапевтов Гештальт-подход». Ведение обучающих программ
                  по гештальт-терапии (1 и 2 ступень). Сотрудничаю с
                  телерадиокомпанией «Брест» в качестве психолога-консультанта,
                  гостя программ «Утренний эспрессо», «Понять и обезвредить». В
                  профессии более 25 лет, с 2013 года работаю только в частной
                  практике.
                </span>
              }
              icon={<Icon icon='book' size='lg' />}
            />
          </Steps>
        </div>
      </div>
    </div>
  )
}

export default History
