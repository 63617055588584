import React, { ReactChildren, ReactNode } from 'react'

import Header from '../header'

import './layout.css'

type Props = {
  children?: ReactChildren | ReactNode
}

const Layout = ({ children }: Props) => {

  return (
    <div className='layout__main'>
      <Header />
      {children}
    </div>
  )
}

export default Layout
